import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { Container } from '../components/Scaffold'
import BlogListing from '../components/BlogListing'

export default ({ data }) => {
	const Posts = data.allMarkdownRemark.edges
		.filter(edge => !!edge.node.frontmatter.date)
		.map(edge => (
			<BlogListing post={edge.node} key={edge.node.id} />
		))

  return (
		<Layout title='Blog - Faith Howley'>
			<Container maxWidth={640}>
				<h1>Writings</h1>
				{Posts}
			</Container>
		</Layout>
	)
}

export const query = graphql`
	query {
		allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
			totalCount
			edges {
				node {
					id
					fields {
						slug
					}
					frontmatter {
						title
						date(formatString: "DD MMMM, YYYY")
					}
					excerpt
				}
			}
		}
	}
`
