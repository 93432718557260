import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'

const BlogPost = styled(Link)`
  box-sizing: border-box;
  display: block;
  max-width: 640px;
  text-decoration: none;
  margin-top: 40px;

  &:not(:first-of-type) {
    padding-top: 40px;
    border-top: 1px solid #FAFAFA;
  }
`

const Date = styled.h5`
  margin: 0;
`

const Title = styled.h3`
  margin: 8px 0 0;

  ${BlogPost}:hover & {
    text-decoration: underline;
  }
`

const Excerpt = styled.p`
  margin: 8px 0 0;
`

const BlogListing = ({ post }) => (
  <BlogPost to={post.fields.slug}>
    <Date>{post.frontmatter.date}</Date>
    <Title>{post.frontmatter.title}</Title>
    <Excerpt>{post.excerpt}</Excerpt>
  </BlogPost>
)

BlogListing.propTypes = {
  post: PropTypes.shape({
     fields: PropTypes.shape({
       slug: PropTypes.string.isRequired
     }),
     frontmatter: PropTypes.shape({
       date: PropTypes.string.isRequired,
       title: PropTypes.string.isRequired
     }),
     excerpt: PropTypes.string.isRequired
  }).isRequired
}

export default BlogListing
